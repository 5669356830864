<template>
  <div class="input-exchange">
    <span ref="hiddenSpan" class="input-exchange__hidden-span">{{ modelValue || placeholder || '0' }}</span>

    <form-input-number
      v-model:value="modelValue"
      :label="label"
      :labelRight="labelRight"
      :placeholder="placeholder"
      :name="name"
      :min="min"
      :max="max"
      :defaultValue="defaultValue"
      :currency="value ? labelCurrencyLeft : undefined"
      :width="inputWidth"
    >
      <div class="input-exchange-block">
        <div class="input-exchange-block__text">≈ {{ labelCurrencyRight }} {{ valueRight }}</div>

        <div class="input-exchange-block__btn" @click.stop.prevent="toggleRevert">
          <atomic-icon id="arrow-sort" />
        </div>
      </div>
    </form-input-number>
    <atomic-hint v-if="props.hint" v-bind="props.hint" />
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted, watch, nextTick } from 'vue';
  import type { IFieldHint } from '@skeleton/types';

  interface IProps {
    value: number | string;
    valueRight: number | string;
    currency?: string;
    min?: number;
    max?: number;
    defaultValue?: number;
    label: string;
    labelRight: string;
    placeholder: string;
    name: string;
    hint?: IFieldHint;
  }

  const props = withDefaults(defineProps<IProps>(), {
    value: 0,
    valueRight: 0,
    placeholder: '',
    name: '',
  });

  const emits = defineEmits(['update:value', 'input', 'toggleRevert']);

  const isCurrencyReversed = ref(false);
  const hiddenSpan = ref<HTMLElement | null>(null);
  const inputWidth = ref(40);

  const modelValue = computed({
    get() {
      return props.value;
    },
    set(value) {
      emits('update:value', Number(value));
      emits('input', Number(value));
      updateWidth();
    },
  });

  const labelCurrencyLeft = computed(() => (isCurrencyReversed.value ? '$' : props.currency));
  const labelCurrencyRight = computed(() => (isCurrencyReversed.value ? props.currency : '$'));

  const toggleRevert = (): void => {
    isCurrencyReversed.value = !isCurrencyReversed.value;
    emits('toggleRevert', isCurrencyReversed.value);
  };

  const updateWidth = () => {
    nextTick(() => {
      if (hiddenSpan.value) {
        inputWidth.value = hiddenSpan.value.offsetWidth + 5;
      }
    });
  };

  watch(() => props.value, updateWidth);

  onMounted(updateWidth);
</script>

<style src="~/assets/styles/components/form/input/exchange.scss" lang="scss" />
